.btn-base,
.btn {
  border-width: $border-thick;
  background-color: $transparent-bg;
  font-weight: $font-weight-normal;
  padding: $padding-base-vertical $padding-base-horizontal;
  @include btn-styles($default-btn-color, $states-btn-color);
  margin-bottom: 5px;
  font-size: $font-size-base;
  line-height: 1.42857143;
  border-radius: $border-radius-extreme;
  &:hover,
  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: none;
    background: $blured-btn-color;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
    background: $blured-btn-color;
  }

  &.btn-icon {
    padding: $padding-base-vertical;
  }
  .controls & {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

// Apply the mixin to the buttons
// .btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary { @include btn-styles($primary-color, $primary-states-color); }
.btn-success { @include btn-styles($success-color, $success-states-color); }
.btn-info    { @include btn-styles($info-color, $info-states-color); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
.btn-login  { @include btn-styles($login-color, $login-states-color); }
.btn-neutral {
  @include btn-styles($white-color, $white-color);

  &:active,
  &.active,
  .open > &.dropdown-toggle{
    background-color: $white-color;
    color: $default-btn-color;
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus{
    color: $default-btn-color;
  }

  &.btn-simple:active,
  &.btn-simple.active{
    background-color: transparent;
  }
}

.btn{
  &:disabled,
  &[disabled],
  &.disabled{
    @include opacity(.5);
  }
}
.btn-round{
  border-width: $border-thin;
  border-radius: $btn-round-radius !important;
  padding: $padding-round-vertical $padding-round-horizontal;

  &.btn-icon{
    padding: $padding-round-vertical;
  }
}

.btn-lg{
  @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-extreme);
  font-weight: $font-weight-normal;
}
.btn-sm{
  @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-extreme);
}

.btn-wd {
  min-width: 140px;
}

@media (max-width: 1200px) {
  .btn-wd {
    min-width: initial;
  }
}

.btn-group.select{
  width: 100%;
}
.btn-group.select .btn{
  text-align: left;
}
.btn-group.select .caret{
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}
.btn-social{
  opacity: 0.85;
}
