
.send_button {
  height: 40px;
  width: 120px;
  margin: 0;
}
.buttons {
  display: flex;
  align-items: center;
}
.clearFile {
  right: 10px;
    top: 6px;
}
