.alert{
    border: 0;
    border-radius: 0;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 14px;

    .container &{
        border-radius: 4px;

    }
    .navbar &{
        border-radius: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 85px;
        width: 100%;
        z-index: 3;
    }
    .navbar:not(.navbar-transparent) &{
        top: 70px;
    }

    button.close{
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 3;
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        outline: 0 !important;
        text-align: center;
        padding: 3px;
        font-weight: 300;

        &:hover{
            background-color: rgba($color: #ffffff, $alpha: 0.5);
        }
    }

    .close ~ span{
        display: block;
        max-width: 89%;
    }

    &[data-notify="container"]{
        padding: 8px 10px 8px 20px;
        border-radius: $border-radius-base;
    }

    &.alert-with-icon{
        padding-left: 38px;
    }
}
.alert{
  &.alert-primary{
    background-color: $default-btn-color;
    color: white;
  }
  &.alert-info{
    background-color: $default-btn-color;
     color: white;
  }
  &.alert-success {
    background-color: $success-color;
     color: white;
  }
  &.alert-warning {
    background-color: $login-color;
     color: white;
  }
  &.alert-danger {
    background-color: $login-color;
     color: white;
  }
}

