
// css transition for tabs
.vue-tabs .tab-content {
  padding-top: 10px;
  min-height: 100px;
  display: flex; // to avoid horizontal scroll when animating
  .tab-container {
    animation: fadeIn 0.5s;
  }
}
.vue-tabs .tab-container{
  width: 100%;
}
.vue-tabs ul.nav-pills {
  border-radius: 10px;
  position: relative;
  display: inline-flex;
  border: 1px solid rgba(223, 227, 233, 0.503047);
  padding: 8px;
  border-radius: 8px; // support: IE9+ ;)
  background-clip: border-box; // support: IE9+
  background-color: #fff;
}
.vue-tabs.error ul.nav-pills {
  border: 1px solid #FB404B;
  &::after {
    content: 'Заполните на всех языках';
    padding-left: 10px;
    position: absolute;
    right: -180px;
    top: 12px;
    width: 180px;
    color: #FB404B;
    font-size: 13px;
  }
}
.vue-tabs .nav-pills>li.tab.active>a,
.vue-tabs .nav-pills>li.tab.active>a:hover,
.vue-tabs .nav-pills>li.tab.active>a:focus {
  background: #637EAF;
  border: none;
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'proxima-nova';
  }
}
.vue-tabs .nav-pills>li.tab>a,
.vue-tabs .nav-pills>li.tab>a:hover,
.vue-tabs .nav-pills>li.tab>a:focus {
  span {
    color: #90AAB7;
    font-size: 14px;
    font-weight: 600;
    font-family: 'proxima-nova';
   }
  padding: 3px 13px;
  background: rgba(11, 45, 116, 0.103584);
  border: none;
}
.vue-tabs ul.nav-pills>li+li {
  margin-left: 0px;
}
