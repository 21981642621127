$timeAnimation: 1s;
.loader {
  .loadWrap {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 99;
    -webkit-font-smoothing: antialiased;
    color: #9A9A9A;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.26);
  }
  .fade2-enter-active,
  .fade2-leave-active {
    transition: all .5s;
  }
  .fade2-enter, .fade2-leave-to {
    transition: all .5s;
    opacity: 0;
  }

  #main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .spinner {
    position: relative;
  }
  .spinner:before, .spinner:after {
    content: "";
    position: relative;
    display: block;
  }
  .spinner:before {
    animation: spinner $timeAnimation cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    width: 3em;
    height: 3em;
    background-color: #fff;
  }
  .spinner:after {
    animation: shadow $timeAnimation cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    bottom: -.5em;
    height: .25em;
    border-radius: 50%;
    background-color: rgba(#000, 0.2);
  }
}
@keyframes spinner {
  50% {
    border-radius: 50%;
    transform: scale(0.5) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(720deg);
  }
}
@keyframes shadow {
  50% {
    transform: scale(0.5);
    background-color: rgba(#000,0.1);
  }
}
