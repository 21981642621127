
.el-table .cell {
  white-space: normal !important;
}
.el-dialog__wrapper .el-dialog {
  background-color: #ffffffa2 !important;
}
.el-dialog__headerbtn .el-dialog__close, .el-dialog__title {
  color: #404040 !important;
}
.download_button {
  min-width: 50%;
}
.btn_wrapper {
  margin-bottom: 2rem;
  text-align: right;
}
