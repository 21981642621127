.box{
    position: relative;
}
/* Ribbon 6 */
.cover-ribbon {
  height: 60px;
  width: 60px;
    position: absolute;
    left: 0;
    top: 0px;
    overflow: hidden;
    z-index: 9999;
  }
  .cover-ribbon .cover-ribbon-inside {
    background: #eb33db;
    color: #050415;
    transform: rotate(45deg);
    position: absolute;
    right: -35px;
    top: 15px;
    padding: 10px;
    min-width: 127px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 9px;
  }

  //new parts from 10.2021

  .cover-ribbon-inside.autumn21 {
    background: #4505f6;
    color: white;
  }
//   