/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2017 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc";
@import "lbd/sidebar-and-main-panel";

@import "lbd/buttons";
@import "lbd/social-buttons";
@import "lbd/inputs";

@import "lbd/alerts";
@import "lbd/tables";

@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";
@import "lbd/tabs-navs-pagination";
@import "lbd/progress_bars";


// Fancy Stuff
@import "lbd/cards";
@import "lbd/chartist";
@import "lbd/tooltip";
@import "lbd/responsive";
@import "lbd/bootstrap-switch";

//plugins css
@import "lbd/plugins/animate";
@import "lbd/plugins/sweetalert2";
@import "lbd/plugins/fullcalendar";
@import "lbd/plugins/chartist";
@import "lbd/plugins/perfect-scrollbar";
@import "lbd/plugins/vue-tabs";

// element-ui customizations
@import "lbd/element/collapse";
@import "lbd/element/tags";
@import "lbd/element/tables";
@import "lbd/element/slider";
@import "lbd/element/date_picker";
@import "lbd/element/select";
@import "lbd/element/input";
@import "lbd/element/modal";

// pages
@import "lbd/pages";


//help
@import "icons";
@import "helpClass";

// loader
@import "loader";
