
@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  -webkit-animation: scroll 5s infinite linear;
  -moz-animation: scroll 5s infinite linear;
  animation: scroll 5s infinite linear;
}
